const App = () => {
    return (
        <div className="container">
            <div className="content">
                <div className="text-center">
                    <img src="assets/images/logo.png" alt="logo" id="logo"/>
                    <p id="title">Coming Soon</p>
                    <p id="para-text">Our website is under contruction. Follow us for an updates</p>
                    <ul className="list-inline">
                        <li className="list-inline-item"><a href="mailto:info@criterionzambia.com"><i className="fas fa-2x fa-paper-plane"></i></a></li>
                    </ul>
                </div>
            </div>
            <p id="footer">Criterion Technologies. All right reserved.</p>
        </div>
    );
}

export default App;
